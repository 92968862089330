import { Routes } from '@angular/router';
import {
  FaqsComponent,
  LocationComponent,
  MissionComponent,
  PeopleComponent,
  PoliciesComponent,
  TrainingComponent,
  InstrumentsComponent,
  HescHipscLinesComponent,
  ReporterOtherGeneEngineeredLinesComponent,
  HpscDerivedCellsComponent,
  ProjectsComponent,
  ProtocolsComponent,
  NewsAndEventsComponent,
  CellReprogramingComponent,
  GeneEditingComponent,
  TriLineageDifferentiationComponent,
  GenerationComponent,
  SearchComponent,
  PruebaComponent,
  AsapLinesComponent
} from './component.index';
import { GeneEditingRelatedComponent } from './gene-editing-related/gene-editing-related.component';

import { HomeComponent } from './home/home.component';
import { HpscCultureComponent } from './hpsc-culture/hpsc-culture.component';
import { HpscDifferentiationComponent } from './hpsc-differentiation/hpsc-differentiation.component';
import { ImmunofluorescenceComponent } from './immunofluorescence/immunofluorescence.component';
import { OtherCellCultureComponent } from './other-cell-culture/other-cell-culture.component';



export const ComponentRoutes: Routes = [

  { path: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: 'location/:firstlevel/:secondlevel/:thirdlevel',
    component: LocationComponent
  },
  {
    path: 'location/:firstlevel/:secondlevel',
    component: LocationComponent
  },
  {
    path: 'location/:firstlevel',
    component: LocationComponent
  },
  { path: 'location', component: LocationComponent },
  { path: 'mission', component: MissionComponent  },
  { path: 'people', component: PeopleComponent  },
  { path: 'policies', component: PoliciesComponent  },
  { path: 'training', component: TrainingComponent },
  {
    path: 'faqs/:firstlevel/:secondlevel/:thirdlevel',
    component: FaqsComponent
  },
  {
    path: 'faqs/:firstlevel/:secondlevel',
    component: FaqsComponent
  },
  {
    path: 'faqs/:firstlevel',
    component: FaqsComponent
  },
  { path: 'faqs', component: FaqsComponent },
  { path: 'instruments', component: InstrumentsComponent },
  { path: 'hesc-hipsc-lines', component: HescHipscLinesComponent },
  { path: 'reporter-other-gene-engineered-lines', component: ReporterOtherGeneEngineeredLinesComponent },
  { path: 'hpsc-derived-cells', component: HpscDerivedCellsComponent },


  {
    path: 'projects/:firstlevel',
    component: ProjectsComponent
  },
  { path: 'projects', component: ProjectsComponent },

  {
    path: 'protocols/:firstlevel',
    component: ProtocolsComponent
  },
  { path: 'protocols', component: ProtocolsComponent },
  { path: 'news-and-events', component: NewsAndEventsComponent },
  { path: 'cell-reprograming', component: CellReprogramingComponent },
  { path: 'gene-editing', component: GeneEditingComponent },
  { path: 'tri-lineage-differentiation', component: TriLineageDifferentiationComponent },
  { path: 'generation', component: GenerationComponent },

  {    path: 'asap-lines',    component: AsapLinesComponent  },
  {    path: 'asap-lines/:firstlevel',    component: AsapLinesComponent  },
  {    path: 'asap-lines/:firstlevel/:secondlevel',    component: AsapLinesComponent  },
  {    path: 'asap-lines/:firstlevel/:secondlevel/:thirdlevel',    component: AsapLinesComponent  },

  {
    path: 'hpsc-culture/:firstlevel/:secondlevel/:thirdlevel',    component: HpscCultureComponent
  },
  {
    path: 'hpsc-culture/:firstlevel/:secondlevel',    component: HpscCultureComponent
  },
  {
    path: 'hpsc-culture/:firstlevel',    component: HpscCultureComponent
  },
  { path: 'hpsc-culture', component: HpscCultureComponent },


  {
    path: 'hpsc-differentiation/:firstlevel/:secondlevel/:thirdlevel',    component: HpscDifferentiationComponent
  },
  {
    path: 'hpsc-differentiation/:firstlevel/:secondlevel',    component: HpscDifferentiationComponent
  },
  {
    path: 'hpsc-differentiation/:firstlevel',    component: HpscDifferentiationComponent
  },
  { path: 'hpsc-differentiation', component: HpscDifferentiationComponent },

  {
    path: 'other-cell-culture/:firstlevel/:secondlevel/:thirdlevel', component: OtherCellCultureComponent
  },
  {
    path: 'other-cell-culture/:firstlevel/:secondlevel', component: OtherCellCultureComponent
  },
  {
    path: 'other-cell-culture/:firstlevel', component: OtherCellCultureComponent
  },
  { path: 'other-cell-culture', component: OtherCellCultureComponent },

  {
    path: 'gene-editing-related/:firstlevel/:secondlevel/:thirdlevel', component: GeneEditingRelatedComponent
  },
  {
    path: 'gene-editing-related/:firstlevel/:secondlevel', component: GeneEditingRelatedComponent
  },
  {
    path: 'gene-editing-related/:firstlevel', component: GeneEditingRelatedComponent
  },
  { path: 'gene-editing-related', component: GeneEditingRelatedComponent },

  {
    path: 'immunofluorescence/:firstlevel/:secondlevel/:thirdlevel', component: ImmunofluorescenceComponent
  },
  {
    path: 'immunofluorescence/:firstlevel/:secondlevel', component: ImmunofluorescenceComponent
  },
  {
    path: 'immunofluorescence/:firstlevel', component: ImmunofluorescenceComponent
  },

  { path: 'immunofluorescence', component: ImmunofluorescenceComponent },

  {
    path: 'prueba/:firstlevel/:secondlevel/:thirdlevel',
    component: PruebaComponent
  },
  {
    path: 'prueba/:firstlevel/:secondlevel',
    component: PruebaComponent
  },
  {
    path: 'prueba/:firstlevel',
    component: PruebaComponent
  },
  {
    path: 'search',
    component: SearchComponent
  },
  {
    path: 'search/:searchText',
    component: SearchComponent
  },

  { path: 'prueba', component: PruebaComponent }

];
